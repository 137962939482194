<template>
  <div class="card">
    <DataTable ref="dt" :value="list"
               :paginator="true" :rows="10"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录">
      <template #header>
        <Button @click="show=true">新增类别</Button>
      </template>
      <Column field="id" header="序号" :sortable="true" headerStyle="width: 10rem"></Column>
      <Column field="title" header="标题" :sortable="false"></Column>
      <Column field="belong" header="属于" :sortable="false">
        <template #body="a">
          {{a.data.belong==1?'网站':'公众号'}}
        </template>
      </Column>
      <Column field="operation" header="操作" :exportable="false">
        <template #body="slotProps">
          <Button @click="addOrUpdateDialog(slotProps.data)">编辑</Button>
          <Button class="p-button-danger p-ml-1" @click="openDelete(slotProps.data)">删除</Button>
        </template>
      </Column>
    </DataTable>
    <Toast/>
    <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="product">你确认要删除 <b>{{ product.title }}</b>?</span>
      </div>
      <template #footer>
        <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
        <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteColumn(product.id)"/>
      </template>
    </Dialog>
    <Dialog style="width: 30%" v-model:visible="show" :header="from.id?'编辑':'添加'">
      <div class="p-flex-column p-mt-3">
        <label>标题：</label>
        <InputText style="width:100%;" v-model="from.title"/>
      </div>
      <div class="p-flex-column p-mt-3">
        <label>所属：</label>
        <Dropdown style="width:100%;" v-model="from.belong"
                  :options="belongList" optionLabel="belong" optionValue="value"
                  placeholder="选择类别"/>
      </div>
      <Button class="p-mt-4" @click="addOrUpdate">保存</Button>
    </Dialog>
  </div>
</template>

<script>
export default {
  name: "ColumnList",
  data() {
    return {
      list: [],
      show: false,
      belongList: [{belong: '网站', value: 1}, {belong: '公众号', value: 2}],
      from: {
        id: null,
        title: null,
        belong: null,
      },
      deleteProductDialog:false,
      product:null,
    }
  },
  mounted() {
    this.getColumn()
  },
  methods: {
    getColumn() {
      this.$http.get('/article/getAllColumn').then((res) => {
        console.log(res.data)
        this.list = res.data
      })
    },
    addOrUpdateDialog(data) {
      this.from = data
      this.show = true
    },
    addOrUpdate() {
      this.$http.put('/article/addColumn', this.from).then(() => {
        this.$toast.add({severity: 'success', summary: '成功', life: 3000});
        this.show = false
        this.getColumn()
      })
    },
    openDelete(data){
      this.product=data
      this.deleteProductDialog=true
    },
    deleteColumn(id) {
      this.$http.post('/article/delColumn/' + id).then(() => {
        this.$toast.add({severity: 'success', summary: '成功', life: 3000});
        this.deleteProductDialog=false
        this.getColumn()
      })
    }
  }
}
</script>

<style scoped>

</style>